import { render, staticRenderFns } from "./paragraphlist-edit.component.vue?vue&type=template&id=38b39889"
import script from "./paragraphlist-edit.component.js?vue&type=script&lang=javascript&external"
export * from "./paragraphlist-edit.component.js?vue&type=script&lang=javascript&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports